import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "@components/layout";
import SEO from "@components/seo";
import AnchorLink from "react-anchor-link-smooth-scroll";
import {
  LazyLoadImage as img,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import _ from "lodash";
import Popup from "@components/modal/popup";

// svg decorations
import WaveTop from "@components/svg-decorations/waveTop";
import WaveBottom from "@components/svg-decorations/waveBottom";

//case study swiper
import CaseStudyScroll from "@components/swipers/caseStudyScroll";

//animation
import { Fade, Zoom } from "react-reveal";

// horizontal scroll
import HorizontalScroll from "@components/horizontalscroll";

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title;
    const siteDescription = this.props.data.site.siteMetadata.description;
    const caseStudy = this.props.data.allSanityCaseStudy.edges;
    const wonLeads = this.props.data.allSanityLeads.edges;

    console.log(wonLeads);

    // const topStudy = caseStudy.filter(
    //   item => item.node.caseStudyPosition === "top"
    // )
    const bottomStudy = caseStudy.filter(
      (item) => item.node.caseStudyPosition === "bottom"
    );

    const partners = _.chunk(wonLeads, 37);

    console.log(partners);
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" description={siteDescription} />

        <div className="hero">
          <div className="container">
            <div className="hero-text-wrapper text-center">
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <h1 className="display-4 font-weight-bold">
                    How To Pack Your Sales Team’s Calendar With High-Ticket
                    Deals (Guaranteed) While Generating Extreme ROI
                  </h1>
                  <p className="lead my-5">
                    It is possible to take your business from $0-$10M+ ARR
                    through finely-tuned outbound sales. Right now though,
                    you’re probably struggling to make it work. How much is
                    stagnation, slow growth, and scrappy solutions costing you?
                    And what if you could get more qualified sales appointments
                    with a rock-solid system based on data and mathematics?
                  </p>
                  <Fade up>
                    <Link to="/" className="btn btn-primary btn-lg btn-red">
                      Take Our Quiz to Get A Price
                    </Link>
                    <div className="mt-3 mb-4">
                      <AnchorLink
                        href="#vsl"
                        offset="80"
                        className="link-white-dotted"
                      >
                        Or click here to watch our video.
                      </AnchorLink>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div style={{ height: "200px" }} />
                {/* <Fade>
                  <div id="vsl" className="embed-container shadow-lg-dark">
                    <LazyLoadComponent>
                      <iframe
                        src="//fast.wistia.net/embed/iframe/ux3gmcwouc"
                        title="VSL"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        allowfullscreen
                        mozallowfullscreen
                        webkitallowfullscreen
                        oallowfullscreen
                        msallowfullscreen
                      />
                    </LazyLoadComponent>
                  </div>
                </Fade> */}
              </div>
            </div>
          </div>
          <div className="hero-bg-image"></div>
          <ul className="list-unstyled moving-bg d-none" id="scenes">
            <li className="scene" data-depth="0.2">
              <img src={require("@images/bg1.svg")} alt="bg1" />
            </li>
            <li className="scene" data-depth="0.6">
              <img src={require("@images/bg2.svg")} alt="bg2" />
            </li>
            <li className="scene" data-depth="1">
              <img src={require("@images/bg3.svg")} alt="bg3" />
            </li>
          </ul>
          <WaveBottom />
        </div>

        {/* <Popup /> */}

        <div id="intro-proof" className="pt-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 text-center">
                <h2 className=" text-primary-gradient mb-4">
                  With Our Proven Method for Chasing High-Ticket Deals
                </h2>
                <p className="lead font-weight-normal mb-3">
                  Your Sales Calendar Can Look Like This...
                </p>
                <Zoom>
                  <img
                    src={require("@images/Calendar-Booking-1.png")}
                    alt="Calendar Booking"
                    className="img-fluid mt-3 shadow"
                  />
                </Zoom>
              </div>
            </div>
          </div>
        </div>

        <div id="clients-logo" className="component pt-100">
          <div className="container text-center">
            <h2 className="text-primary-gradient mb-4">
              Just Like Hundreds of Businesses Like Yours Are Already...
            </h2>
            <p className="lead font-weight-normal mb-3">
              We’ve set high ticket appointments with companies like...
            </p>
          </div>
          <LazyLoadComponent>
            <HorizontalScroll>
              <div className="scroll-container">
                <div className="hidden-component pb-0">
                  {partners.map((item, idx) => (
                    <ul className="list-unstyled the-content d-flex justify-content-start align-items-center mb-0">
                      {item.map((i, d) => {
                        return (
                          <li className="mx-4 my-3 client-logo">
                            <img
                              className="img-fluid"
                              width="180px"
                              src={i.node.logo.asset.fixed.src}
                              alt={i.node.title}
                            />
                          </li>
                        );
                      })}
                    </ul>
                  ))}
                </div>
              </div>
            </HorizontalScroll>
          </LazyLoadComponent>
          {/*<div className="animated-scroll-wrapper">
            <div className="scroll-down text-center">
              <AnchorLink href="#" offset="0">
                <span></span>
                Scroll
              </AnchorLink>
            </div>
          </div>*/}
        </div>

        <div className="wave-blob-section">
          <WaveTop />
          <WaveBottom />
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <p className="lead text-white mb-5">Let's Be Honest...</p>
                <h2 className="text-teal mb-5">
                  For 99% of High-Ticket Businesses, Outbound Selling Doesn’t
                  Work Because When They Go It Alone, They Rely on Cheap
                  Strategies and Fake Gurus’ Advice. Are You One of Them?
                </h2>
                <p className="mb-5 text-white">
                  Many agencies promise more leads and less time spent
                  prospecting. But that’s NOT what you need. You need more
                  guaranteed appointments on the calendar. You need primed
                  deals, not more empty marketing activity. At WebriQ, we know
                  that you’re tired of big spending with low ROI. We’ve built a
                  system that aligns your goals with your prospecting efforts —
                  and we don't take it lightly. We handle the end-to-end
                  prospecting and appointment setting. You close the deals.
                </p>
                <h4 className="text-teal mb-5">
                  Let’s Get You More Qualified Appointments, Not More to Do
                </h4>
                <Link to="/" className="btn btn-primary btn-lg btn-red">
                  Get a Price
                </Link>
                <div className="mt-3">
                  <Link to="/" className="text-white link-white-dotted">
                    Or click here to watch our video.
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>

        <div className="py-100 border-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                    <Fade>
                      <img
                        className="img-fluid shadow mt-4 mt-md-0"
                        src={require("@images/ROIcalculator2.png")}
                        alt="ROI Calculator"
                      />
                    </Fade>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-1 text-center text-lg-left">
                    <Fade left delay={500}>
                      <div className="rounded-lg p-4 bg-dark-blue speech-arrow-left">
                        <div className="d-lg-flex align-items-center">
                          <img
                            src={require("@images/exclamation.svg")}
                            width={60}
                            alt=""
                            className="mr-3 mb-2 mb-md-0"
                          />
                          <h4 className="text-white">
                            Cheap Automated Tactics Are Empty, And They Won’t
                            Turn Into Sales
                          </h4>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
                <p className="mt-5">
                  It feels good to automate your outreach, but high-ticket sales
                  is way more complicated than most realize.
                  <ul className="crossed mt-4">
                    <li>
                      You may generate “leads”, but then you’re faced with the
                      follow up — and they don’t know you, like you, or trust
                      you.
                    </li>
                    <li>
                      You aren’t tracking your metrics and therefore can’t make
                      data-driven decisions.
                    </li>
                    <li>
                      You automated, and because you took a short-cut, you
                      didn’t actually spend time customizing your message to
                      that affluent prospect... So why should they care about
                      your solution?
                    </li>
                  </ul>
                </p>
                <p>
                  Instead of wasting your efforts on impersonal efforts built on
                  a foundation of sand, choose an ironclad, data-driven outbound
                  process that guarantees appointments on your calendar. Your
                  sales reps can spend more time closing deals rather than
                  chasing ghosts — and you’ll rest assured that no qualified
                  lead is left behind.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-100 bg-white border-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6 order-1 order-lg-1 text-center text-lg-left">
                    <Fade right delay={500}>
                      <div className="rounded-lg p-4 bg-dark-blue speech-arrow-right">
                        <div className="d-lg-flex align-items-center">
                          <img
                            src={require("@images/exclamation.svg")}
                            width={60}
                            alt=""
                            className="mr-3 mb-2 mb-md-0"
                          />
                          <h4 className="text-white">
                            Scrappy “Growth Hacks” Won’t Cut It
                          </h4>
                        </div>
                      </div>
                    </Fade>
                  </div>
                  <div className="col-lg-6 order-2 order-lg-2 mt-5 mt-lg-0">
                    <Fade>
                      <img
                        className="img-fluid shadow mt-4 mt-md-0"
                        src={require("@images/LEadgenperformance.png")}
                        alt="Lead Generation Performance"
                      />
                    </Fade>
                  </div>
                </div>
                <p className="mt-5">
                  The truth is, there is no quick fix or growth hack that can
                  ramp your sales the way you truly need.
                </p>
                <ul className="crossed">
                  <li>Your messages could be getting ignored.</li>
                  <li>Your emails are probably getting stuck in spam.</li>
                  <li>
                    You might be relying on cheap tricks instead of driving
                    actionable results.
                  </li>
                </ul>
                <p>
                  In high-ticket sales, well-intended attempts to save a quick
                  buck have an extreme cost in the long run. We’ve been around
                  the block in this space, and we’ve seen this story play out
                  hundreds of times… Businesses buy into an automation tool or
                  sketchy service that claims to save time and make more money,
                  for the price of a few lattes...
                </p>
                <p>
                  Instead, they get banned by amazing platforms like LinkedIn,
                  they get blacklisted in their email marketing, and they blow
                  through otherwise qualified leads because those short-cuts
                  just burned your lead list.
                </p>
                <p>
                  At WebriQ, we might be ACTUAL magicians, because we can ensure
                  your sales team’s calendar gets flooded with qualified leads —
                  At least, that’s what our clients are saying.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-100 border-bottom">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-6 order-2 order-lg-1 mt-5 mt-lg-0">
                    <Fade>
                      <img
                        className="img-fluid shadow mt-4 mt-md-0"
                        src={require("@images/ROIcalculator.png")}
                        alt="ROI Calculator"
                      />
                    </Fade>
                  </div>
                  <div className="col-lg-6 order-1 order-lg-2 text-center text-lg-left">
                    <Fade left delay={500}>
                      <div className="rounded-lg p-4 bg-dark-blue speech-arrow-left">
                        <div className="d-lg-flex align-items-center">
                          <img
                            src={require("@images/exclamation.svg")}
                            width={60}
                            alt=""
                            className="mr-3 mb-2 mb-md-0"
                          />
                          <h4 className="text-white">
                            How Much Is Misguided Outbound Selling And Slow
                            Growth Actually Costing You?
                          </h4>
                        </div>
                      </div>
                    </Fade>
                  </div>
                </div>
                <p className="mt-5">
                  If your MRR has been up and down, instead of up and to the
                  right… You probably don’t have control over your business.
                </p>
                <p>
                  Relying on referrals alone is extremely costly, and
                  pay-per-click isn’t enough to drive deals to the finish line.
                </p>
                <p>
                  Fact is, if you aren’t growing, you’re dying, and stagnation
                  alone is costing you hundreds of thousands of dollars, if not
                  millions in lost opportunity.
                </p>
                <p>
                  If you’re focused on figuring this out yourself, and you think
                  you can get by with hiring a team of SDRs, get ready to be
                  disappointed, because not only will it cost you $180K-$250K
                  for half-decent help, but a team of Sales Development Reps
                  without process is like an army with no weapons.
                </p>
                <p>
                  Tactics have changed, and if your reps are trying to chop a
                  tree with a dull axe, they’re going to spend hundreds of hours
                  per month trying to figure out how to get by, with no
                  guarantee of success (not to mention you still have to pay
                  thousands of dollars per month on tools and data alone).
                </p>
                <p>Think you can build this yourself? Think again.</p>
                <p>
                  It costs anywhere from $250,000 to $500,000 per year to build
                  the kind of infrastructure necessary to produce extreme
                  results at the scale you actually need to expedite your growth
                  curve.
                </p>
                <p>
                  You can certainly build it, but can you guarantee results and
                  solve for every nuance and hiccup?
                </p>
                <p>
                  Stop wasting time on lottery-based outreach programs that
                  waste great leads and don’t deliver results.
                </p>
                <p>
                  Here’s the point: the real cost of doing your outbound selling
                  incorrectly can be catastrophic to your business. You need
                  outbound to properly scale up, and you won’t be able to
                  succeed in a reasonable timeframe without fixing the lead
                  problem in your pipeline. It’s that simple.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wave-blob-section text-white">
          <WaveTop />
          <WaveBottom />
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <Fade>
                  <h2 className="display-5 text-teal">
                    Optimize Your Outreach with WebriQ
                  </h2>
                </Fade>
                <h4 className="font-weight-normal mb-5">
                  You Can Reliably Schedule Calls with Qualified Leads If You:
                </h4>
                <div className="row justify-content-center my-5">
                  <div className="col-md-8">
                    <ul className="checked text-left">
                      <li>
                        <strong>Do the right math</strong> rather than “trusting
                        in the process”
                      </li>
                      <li>
                        <strong>Hand craft the right lead list</strong> rather
                        than spraying and praying
                      </li>
                      <li>
                        <strong>Lay the right foundation</strong> rather than
                        scaling up a leaky bucket
                      </li>
                      <li>
                        <strong>Personalize the right sales messages</strong>{" "}
                        rather than spamming everyone with robotic content{" "}
                      </li>
                      <li>
                        <strong>Send the right follow-up</strong> rather than
                        letting great deals fall through the cracks
                      </li>
                      <li>
                        <strong>Clearly report on the right KPIs</strong> rather
                        than guessing at your progress and goals
                      </li>
                      <li>
                        <strong>Leverage the right systems and team</strong>{" "}
                        rather than flushing your growth budget down the toilet
                      </li>
                    </ul>
                  </div>
                </div>

                <p>
                  With WebriQ, you’ll stop “playing the lottery” with outbound
                  selling and start executing outbound the right way — the
                  predictable way. We’ve turned sales prospecting into a
                  science, which means you can begin seeing results in less than
                  30 days.
                </p>
                <div className="mt-5">
                  <Link to="/" className="btn btn-primary btn-lg btn-red">
                    Take Our Quiz Now
                  </Link>
                  <div className="mt-3">
                    <Link to="/" className="link-white-dotted">
                      Or click here to watch our video.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>

        <div className="py-100 border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-1 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/ROIcalculator3.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>1</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Do the Right Math to Validate Your Outbound Approach
                  </h2>
                  <p>
                    Someone wise once said: “Take the long road, it’s faster” —
                    and in your case, that “long-road” is MATH. You need to know
                    how your ROI will play out before you dive in. That’s why we
                    follow Warren Buffet’s two rules:
                  </p>
                  <p>
                    <strong>Rule number 1:</strong> Never lose money. <br />
                    <strong>Rule number 2:</strong> Don’t forget rule number 1.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          Why don’t other agencies run the math with me before
                          we begin?
                        </span>
                        <br />
                        It’s not easy to do, and they usually don’t have the
                        right experience! You need powerful analytics and a full
                        set of data from your business. Our proprietary
                        algorithms handle the math for you, so we can truly
                        optimize your ROI.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    Do-overs are expensive. Let’s get it right the first time.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 bg-light border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-2 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/LeadsheetExample.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-1">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>2</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Craft A Laser-Focused Lead List
                  </h2>
                  <p className="mt-4">
                    If you’ve done outbound selling the old-fashioned way, you
                    were probably working off leads lists that were never hand
                    vetted. You had no way of knowing if the leads were good,
                    and in many cases, it’s impossible to see where your ads or
                    emails are going. In all likelihood, you were spending money
                    on people who were never going to book a call.
                  </p>
                  <p>
                    The most critical step in any successful outbound sales
                    initiative is to compile a hyper-targeted leads list. That
                    means going beyond basic search or relying on third-party
                    providers who don’t know your business.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          Why can’t I use a low-budget company to compile a
                          leads list or use Sales Navigator to search for me?
                        </span>
                        <br />
                        Many of these companies use shady tactics to generate
                        leads. Platforms are cracking down on this behavior, so
                        if you use these “services,” you could end up getting
                        blacklisted or banned. As the saying goes, you get what
                        you pay for.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    We’ll help you develop a list of leads who perfectly match
                    your ideal customer profile, and we’ll isolate the decision
                    makers.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-1 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/outbound-foundation.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>3</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Lay The Outbound Foundation
                  </h2>
                  <p>
                    The hidden enemy of every outbound sales initiative is the
                    spam box! If you’re not extremely careful, not only will the
                    vast majority of your outbound emails end up in spam, but
                    you also risk your domain becoming blacklisted.
                  </p>
                  <p>
                    You need to set up an outbound system that’s fully
                    compliant, and highly sophisticated. When your technical
                    elements are configured correctly, it’s possible to achieve
                    a {"<"}0.05% spam rate over time — even in an ice-cold
                    outbound campaign.
                  </p>
                  <p>
                    Don’t let autoresponder software and poor spam monitoring
                    ruin your campaigns. Build a solid foundation and
                    personalize every single message to give your initiative
                    superhero-level effectiveness.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          What’s wrong with drip email providers such as
                          Mailchimp or ActiveCampaign?
                        </span>
                        <br />
                        These simply aren’t designed for cold outreach. In fact,
                        if you use them for that, they’ll probably cancel your
                        account. You are required to only send emails to those
                        that opt-in. Drip systems are great for newsletters and
                        campaigns to active customers, but they simply won’t
                        work for outbound selling.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    We've provisioned hundreds of VPN-protected emails at IP
                    addresses across the world that we use to build up an elite
                    sender reputation. This means we can help you cold-email
                    prospects in a way that won’t violate spam laws, and will
                    actually get delivered to the recipients primary inbox.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 bg-light border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                {/* <div className="col-lg-6 order-2 order-lg-2 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/outreach-message-sequence.png")}
                    alt="ROI Calculator"
                  />
                </div> */}
                <div className="col-lg-6 order-1 order-lg-1">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>4</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Create a Highly Relevant Outreach Message Sequence
                  </h2>
                  <p className="mt-4">
                    These days, everyone is inundated with messages, and they
                    will swiftly ignore, and most affluent people will delete
                    anything that seems spammy or irrelevant. That’s why you
                    MUST customize every email with a relevant first line that’s
                    handcrafted for each prospect in the campaign.
                  </p>
                  <p>
                    Unfortunately, automated cold-outreach tools can only send
                    generic messages. If it’s a cheap managed service, it’s
                    often written by non-fluent English speakers.
                  </p>
                  <p>Your prospects can see right through it.</p>
                  <p>
                    Broadcasting messages with no relevance will result in
                    apathy or annoyance, not sales.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          Does this mean I can’t outsource the writing? Do I
                          really need a personalized intro to each email?
                        </span>
                        <br />
                        You can absolutely have someone write the copy for you.
                        Invest in talented copywriters who can help you make an
                        impact. That’s the kind of spice you need to develop a
                        relationship deep enough to book a call. And yes, you
                        need to start every message with a highly targeted
                        intro. This alone can boost your cold email response
                        rate from {"<"}1% to 15% or more!
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    Personalized and relevant messages can cut through the
                    noise, and will make your prospects excited to speak with
                    you. You won’t be able to build Know–Like–Trust
                    relationships at scale without it.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-1 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/schematic3.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>5</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Perform Daily Outbound Outreach and Ironclad Follow Up
                  </h2>
                  <p>
                    Cold outreach can be quite challenging to scale up. With
                    each prospect, you’re adding yet more work to your reps’
                    schedules, and no one is sure how to follow up on initial
                    messages. Your team is now juggling both initial contacts
                    and sales appointments. You’re forced to “churn and burn”
                    prospects just to save yourself time.
                  </p>
                  <p>
                    The truth is, there is no way to truly automate the process
                    — not if you’re doing it right. You can’t “set it and forget
                    it” with these kinds of campaigns. They’re a full-time job.
                  </p>
                  <p>
                    That means that scaling up isn’t a matter of generating more
                    leads. You need to generate the RIGHT leads and free up your
                    closer’s time so they can focus on closing more deals.{" "}
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          How much follow-up is actually necessary?
                        </span>
                        <br />
                        We have a saying at WebriQ: Frequency without Relevancy
                        is annoying. But Frequency with Relevancy is
                        omnipresence, and we’ve dialed it into a science. With
                        our help, you can touch prospects enough times to close
                        high-ticket deals. That may be close to 30 times! When
                        you attempt to automate this, you’ll fail. The key is to
                        start with great data and let your reps do their thing.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    At WebriQ, we put the saying “the fortune is in the
                    follow-up” into action. Your cash flow skyrockets since your
                    closers are focused on making deals, and your cost for
                    prospecting remains low and highly profitable.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 bg-light border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-2 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/performancemetricskenfee.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-1">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>6</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Clearly Report & Track Performance and Refine Your Strategy
                  </h2>
                  <p className="mt-4">
                    Understanding performance is the key to any sales
                    initiative, and the only possible way to recognize
                    effectiveness is through tracking, reporting, and measuring
                    your data.
                  </p>
                  <p>
                    Without a good reporting structure, you can’t accurately
                    make decisions in your outbound campaigns. The secret to
                    booking your pipeline to the brim often lies in stirring up
                    the dozens of dusty old messages. In our approach, you
                    conduct split-tests and revise monthly until you have a
                    battle-tested campaign that drives leads straight into your
                    calendar like clockwork.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          What’s the real value of reporting, and is it really
                          worth the time?
                        </span>
                        <br />
                        You simply can’t measure what you don’t report and can’t
                        properly follow up with prospects you're not tracking.
                        Reporting is how you’ll know if your strategy is truly
                        profitable. If you want to have a proper impact on your
                        leads and not just burn through them, reporting is not
                        only worth your time but also paramount to outbound
                        selling success.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    With WebriQ, you’re no longer wondering whether or not
                    qualified sales calls will meet your projections. You can
                    sit back and relax as we flood your calendar with qualified
                    sales opportunities that are excited to hear about your
                    solution.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        <div className="py-100 border-bottom">
          <Fade up>
            <div className="container">
              <div className="row justify-content-between align-items-center pb-5">
                <div className="col-lg-6 order-2 order-lg-1 text-center my-5 my-lg-0">
                  <img
                    className="img-fluid shadow mt-4 mt-md-0"
                    src={require("@images/calendars.png")}
                    alt="ROI Calculator"
                  />
                </div>
                <div className="col-lg-6 order-1 order-lg-2">
                  <div className="thesis-step-num mx-auto mx-lg-0">
                    Step <span>7</span>
                  </div>
                  <h2 className="text-primary-gradient my-4 text-center text-lg-left">
                    Bring Qualified Leads Down the Funnel To The Point Of Sale
                  </h2>
                  <p>
                    Before a lead converts, they need to fully understand your
                    offering and feel compelled to solve their pain. More often
                    than not, it will take a good amount of targeted, authentic
                    follow-up messages to get MQLs to book a call on your team’s
                    calendar.
                  </p>
                  <p>
                    The work doesn’t stop when you get a lead. Once that reply
                    comes in, it’s time to bring that qualified deal down-funnel
                    until you’re virtually face-to-face. Unfortunately, without
                    a good system in place, it’s all too easy to lose great
                    opportunities along the way. It can take 4h-6h of lead
                    chasing to get a prospect through to the point of receiving
                    a proper pitch… But if you’re willing to put in the work, to
                    the victor go the spoils...
                  </p>
                  <p>
                    With clear messaging/reminder schedules, a procedure for
                    handling hot leads, and the right messaging to encourage
                    prospects to keep their appointments, you could be booking
                    15-25 high-ticket sales calls per month, per rep — if not
                    much more.
                  </p>
                </div>
              </div>
              <div className="row justify-content-center pt-5">
                <div className="col-lg-8">
                  <div className="border rounded-lg p-4 bg-dark-blue">
                    <ul className="question-list text-white m-0">
                      <li>
                        <span className="font-weight-bold text-teal">
                          What happens if I just do outbound but don’t lead
                          prospects down the funnel?
                        </span>
                        <br />
                        In a nutshell: you’ll never get the chance to pitch, and
                        great leads will fall through your fingers. Your
                        prospects have busy lives, so leading them down the
                        funnel is your responsibility, and they rarely make it
                        easy. Great follow-up shows that you respect them and
                        that you are a trustworthy person worth talking to. Not
                        taking the lead simply wastes everyone’s time.
                      </li>
                    </ul>
                  </div>
                  <h5 className="pt-5 mt-4 text-center text-primary-gradient">
                    Finally, you have a fruitful follow-up system in place, with
                    every message customized for each prospect and a clear
                    nurture plan laid out. With WebriQ on your side, guessing
                    and lost leads are permanently removed from the equation.
                  </h5>
                </div>
              </div>
            </div>
          </Fade>
        </div>

        {/* <div className="casestudy-section component text-white" id="casestudy">
          <div className="text-center sticky-top-header">
            <h2 className="text-white">
              This is Why Hundreds of Customers Love WebriQ
            </h2>
          </div>
          <LazyLoadComponent>
            <CaseStudyScroll data={bottomStudy} />
          </LazyLoadComponent>
          <div className="blob-bg"></div>
        </div> */}

        <div className="pt-100 border-top">
          <div className="container ">
            <div className="row justify-content-center text-center">
              <div className="col-lg-9">
                <Zoom>
                  <img
                    src={require("@images/companies-booked-calendars.png")}
                    alt="logos"
                    className="img-fluid mb-5"
                  />
                </Zoom>
                <h2 className="text-primary-gradient">
                  These Companies Are Already Getting Fully Booked Calendars —
                  When Will You?
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="wave-blob-section text-white">
          <WaveTop />
          <WaveBottom />
          <div className="container text-center">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <h4 className="font-weight-normal">
                  With the right math, robust testing, and a well-oiled sales
                  process, you can get more qualified leads to book appointments
                  — without spending money on reps that never produce.
                </h4>

                <div className="row justify-content-center my-5">
                  <div className="col-md-8">
                    <h2 className="display-5 text-teal mb-5">
                      WebriQ reduces your risk and gets you results:
                    </h2>
                    <ul className="checked text-left">
                      <li>We’ll Hand Vet Every Lead So They Match Your ICP</li>
                      <li>
                        Your Market Will Respond In 30 Days, Or We’ll Issue A
                        Full Refund
                      </li>
                      <li>
                        If We Don’t Book Enough Calls For You To Predictably
                        Generate a 2x ROI in 90 Days, We Work For Free
                      </li>
                    </ul>
                  </div>
                </div>
                <Link to="/" className="btn btn-primary btn-lg btn-red">
                  Get a Price
                </Link>
              </div>
            </div>
          </div>
          <div className="blob-bg"></div>
        </div>

        <div className="py-100">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9 order-1 order-lg-1 text-center">
                <Fade up>
                  <div className="rounded-lg p-4 bg-dark-blue speech-arrow-bottom d-inline-block px-5">
                    <img
                      src={require("@images/exclamation.svg")}
                      width={60}
                      alt=""
                      className="d-block mb-2 mx-auto"
                    />
                    <h4 className="text-white">
                      You have the Right to Be Skeptical
                    </h4>
                  </div>
                </Fade>
                <p className="lead text-muted mt-5">
                  If you’ve experienced the talking heads who are telling you
                  how to market, but their advice hasn’t made an ounce of
                  difference for your sales… we’ve been there too.
                </p>
              </div>
            </div>
            <div className="row align-items-center mt-5">
              <div className="col-lg-6 mx-auto">
                <p>
                  Not only that, but if you’ve been shopping around for lead gen
                  providers, and you had a sneaking suspicion that the spammy
                  low-cost, no commitment, shady-looking businesses out there
                  that promise things like “Done For You LinkedIn Outreach” or
                  some automated approach is too good to be true and could get
                  you banned or blacklisted — you’d be right there as well.
                </p>
                <p>
                  That’s why we created this in-depth video detailing exactly
                  how you can finally get outbound right, and accelerate your
                  progress to your sales goals.
                </p>
              </div>
              {/* <div className="col-lg-6">
                <div className="embed-container shadow-lg-dark">
                  <LazyLoadComponent>
                    <iframe
                      src="//fast.wistia.net/embed/iframe/ux3gmcwouc"
                      title="VSL"
                      allowtransparency="true"
                      frameborder="0"
                      scrolling="no"
                      allowfullscreen
                      mozallowfullscreen
                      webkitallowfullscreen
                      oallowfullscreen
                      msallowfullscreen
                    />
                  </LazyLoadComponent>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: title, order: ASC }) {
      edges {
        node {
          id
          title
          logo {
            id
            title
            logo {
              alt
              asset {
                id
                fixed(width: 200) {
                  src
                }
              }
            }
          }
          headshots {
            id
            image {
              alt
              asset {
                id
                fixed(width: 200, height: 200) {
                  src
                }
              }
            }
          }
          graphImage {
            alt
            asset {
              fluid {
                src
              }
            }
          }
          state1
          state2
          timeFrame
          caseStudyPosition
        }
      }
    }
    allSanityHeadshot {
      edges {
        node {
          id
          name
          position
          company
          description
          image {
            alt
            asset {
              id
              fixed(width: 200, height: 200) {
                src
              }
            }
          }
        }
      }
    }
    allSanityLeads(
      filter: { publishArea: { eq: "leads won" } }
      sort: { fields: [title], order: ASC }
    ) {
      edges {
        node {
          id
          title
          logo {
            alt
            asset {
              id
              fixed(width: 150) {
                src
              }
            }
          }
        }
      }
    }
    allSanityPost(sort: { order: ASC, fields: publishedAt }, limit: 3) {
      edges {
        node {
          id
          title
          mainImage {
            asset {
              fluid {
                src
              }
            }
          }
          slug {
            current
          }
          excerpt
          publishedAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
    allSanityObjectionVideos(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          videoSource
          title
          publishDate(formatString: "MMM-DD-YYYY")
        }
      }
    }
    sanityCompanyInfo {
      id
      faq {
        _key
        title
        body
      }
    }
  }
`;
